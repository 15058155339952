<template>
	<div  class="fixed">
   
 	
<div :class="'zt'+status4" >

   <!-- <div>时间段:{{starttime}}至{{endtime}}</div> -->
 <!-- <toast v-model="show4" type="text">请选择时间</toast> -->
    <div class="mobile-top" >
      <div class="sel-time">
        <p>开始时间</p>
        <p class="start-date">{{starttime}}</p>
      </div>
      <div class="unsel-time">
        <p>结束时间</p>
        <p class="end-date">{{endtime==''?'请选择结束日期':endtime}}</p>
      </div>
    </div>

    <div class="title">
      <div class="btn" @click="last()" 
       >上一月</div>
      <div class="text">{{Year}}年{{month}}月</div>
      <div class="btn" @click="next()">下一月</div>
    </div>
 
    <div class="head">
      <!--<div class="days" v-for="(item,index) in ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']" :key="index">-->
      <div class="days" v-for="(item,index) in ['周日','周一','周二','周三','周四','周五','周六']" :key="index">
        {{item}}
      </div>
    </div>
 
    <div class="wrap">
      <!--<div class="span" v-for="(item,index) in calendarList" :key="index" @click="click(item.count)" :class="item==''?'kong':item.count>nowtime?'noclick':(item.count>=starttime&&item.count<=endtime)||item.count==starttime?'active':''">
        {{item.value}}
      </div>-->
       <div class="span" v-for="(item,index) in calendarList" :key="index" @click="click(item.count)" 
       	:class="item==''?'kong':!item.count?'':(item.count>=starttime&&item.count<=endtime)||item.count==starttime?'active':''">
        {{item.value}}
      </div>
    </div>
 
    <div class="bottombtn">
      <span class="cancle-btn" @click='cancle()'>取消</span>
      <span class="sure-btn" @click='firm()'>确定</span>
    </div>
  </div>
  </div>
</template>
 
<script>
	import {Toast} from 'vant'
  export default {
//  name: 'Biji'
			components: {
			  	Toast
			 },

    data() {
      return {
        nowtime: '', //当前日期的时间戳
        clickitem: 0, //点击的时间戳
        clickcount: 0, //点击次数
        starttime: '', //开始时间 数字   默认选中当天日期
        endtime: '', //结束时间 数字
 		    show4:false,
        Year: new Date().getFullYear(),   //日历上的年份
        month: new Date().getMonth() + 1, //日历上的月份
        Day: new Date().getDate(),        //日历上的天份
 
        nowYear: new Date().getFullYear(),
        nowmonth: new Date().getMonth() + 1,
        nowDay: new Date().getDate(),
 
        calendarList: [],
      }
    },
    props: {
          status4: {
            type: Number,
            default: 0
          },
	    },
    created() {
      this.Draw(this.nowYear, this.nowmonth);
 
      let time_month = this.nowmonth; //现在的月份
      let time_day = this.nowDay; //现在的天数
      if (this.nowmonth < 10) {
        time_month = 0 + '' + this.nowmonth;
      }
      if (this.nowDay < 10) {
        time_day = 0 + '' + this.nowDay;
      }
 
      this.nowtime = this.nowYear + '-' + time_month + '-' + time_day;
      this.starttime = this.nowtime;
      this.endtime = this.nowtime;
    },
    computed: {
      
    },
 
    methods: {
 
      Draw: function (Year, Month) {
 
        //日期列表
        var calendar = [];
 
        //用当月第一天在一周中的日期值作为当月离第一天的天数(获取当月第一天是周几)
        for (var i = 1, firstDay = new Date(Year, Month - 1, 1).getDay(); i <= firstDay; i++) {
           calendar.push("");
        }
 
        //用当月最后一天在一个月中的日期值作为当月的天数
        for (var i = 1, monthDay = new Date(Year, Month, 0).getDate(); i <= monthDay; i++) {
 
          let time_month = Month;
          let time_day = i;
          if (Month < 10) {
            time_month = 0 + '' + Month;
          }
          if (i < 10) {
            time_day = 0 + '' + i;
          }
          calendar.push({
            value: i,
            count: Year + '' + time_month + '' + time_day
          })
        }
        this.calendarList = calendar;
//         console.log(calendar)
      },
 
      last() {
        this.month--;
        if (this.month == 0) {
          this.month = 12;
          this.Year--;
        }
 
        this.Draw(this.Year, this.month);
      },
 
      next() {
        this.month++;
        if (this.month == 13) {
          this.month = 1;
          this.Year++;
        }
 
        this.Draw(this.Year, this.month);
      },
 
 
      click(item) {
        this.clickcount++;
        this.clickitem = item;
 		
        //开始日期
        if (this.clickcount % 2 == 1) {
          this.starttime = this.clickitem;
          this.endtime = ''
        } else {
          this.endtime = this.clickitem;
          if (this.starttime > this.endtime) {
            this.endtime = this.starttime;
            this.starttime = this.clickitem;
          }
        }
      },
 
 
      firm(){
        let start,end;
        //console.log(this.starttime+'???');
        if(this.starttime==this.endtime){
          start = this.starttime +' 00:00:00';
          end = this.endtime +' 23:59:59';
        }
        start = (new Date(this.starttime)).getTime()/1000;
        end = (new Date(this.endtime)).getTime()/1000;
        this.endtime!=""&&this.starttime!=""?this.$emit("gettime", [start,end],false):this.show4=true;
        this.status4=0;
      },
 
      cancle(){
      //this.starttime = this.nowtime;
      //this.endtime = this.nowtime;
      this.status4=0;
      console.log(this.status4);
      }
 
    }
 
  }
 
</script>
 
<style scoped lang="scss">
  /*@import "../common/common";*/
  .zt0{
    display: none;
  }
  .zt1{
    display: block;
  }
  .wrap {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 1rem;
  }
 
  .span {
    width: 14%;
    height: 35px;
    background: #fff;
    color: #000;
    float: left;
    text-align: center;
    line-height: 35px;
 
    &.active {
      background: #e93323;
      color: #fff;
    }
 
    &.noclick {
      pointer-events: none;
      background: #ccc;
    }
 
    &.kong {
      background: #fff;
      pointer-events: none;
    }
  }
 
  .mobile-top {
    display: flex;
    height: 45px;
    flex-wrap: nowrap;
    background: #fff;
    padding: 0.1rem 0;
    .sel-time {
      text-align: center;
      width: 50%;
      color: #000;
      // border-bottom: solid 2px #2a81e8;
      .start-date{
        color: #b1b1b1;
        margin-top: 0.05rem;
      }
    }
 
    .unsel-time {
      text-align: center;
      width: 50%;
      color: #000;
      .end-date{
        color: #b1b1b1;
         margin-top: 0.05rem;
      }
    }
  }
 
  .title{
    width: 100%;
    height: 40px;
    background-color: #e93323;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 40px;
 
    .btn{
      width: 20%;   
      &.noclick{
        pointer-events: none;
         background: #ccc;
      }
    }
    .text{
      flex: 1;
      width: 60%;
    }
  }
 
  .head{
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  height: 40px;
  line-height: 40px;
  .days{
    flex: 1;
    height: 40px;
  }
  }
 
 
 
  .bottombtn {
    height: 45px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
 	bottom: 1px;
 	position: fixed;
 	text-align: center;
    span {
      flex: 1;
	line-height: 45px;
	text-align: center;
	border: 1px solid #e2e0e0;
	margin: 1px;
    }
 	
    .sure-btn {
      background: #e93323;
      color: #fff;
    }
    .cancle-btn{
      color: #000;
    }
  }
 .fixed{
 	position:fixed;
 	bottom: 0px;
 	/*top: 20%;*/
 	width: 100%;
 	height:402px;
 	text-align: center;
 	z-index: 1000;
 	
 }
</style>